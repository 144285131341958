.child-nav {
  height: 40px;
  display: flex;
  border-bottom: 1px solid#e9e9e9;
  box-shadow: 0px 0px 5px #ccc;
  background-color: #fff;
  font-size: 14px;
  z-index: 999;
}

.child-nav>div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left, .right {
  flex: 1;
}
.content {
  flex: 3;
  font-size: 16px;
}