.waterMark {
  position: fixed;
  top: 0;
  left: 0;
  transform: translateZ(0);
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  pointer-events: none;
  display: flex;
  flex-wrap: wrap;
}

.mark {
  width: 33.33%;
  height: 25vh;
  transform: rotate(-30deg);
  color: rgba(0, 0, 0, .15);
}