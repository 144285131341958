:root {
  --main-padding: 12px;
  /* Zarm定制主题 */
}

::-webkit-scrollbar {
  display: none;
}

* {
  scrollbar-width: none;
}

*:not(input, textarea) {
  user-select: none;
}

/* 清除 移动端 点击时的元素背景色 */
div {
  -webkit-tap-highlight-color: transparent;
}

/* 适配底部"黑线" */
body {
  /* 兼容 IOS<11.2 */
  padding-bottom: constant(safe-area-inset-bottom);
  /* 兼容 IOS>11.2 */
  padding-bottom: env(safe-area-inset-bottom);
  background-color: #f6f6f6 !important;
  overflow: hidden;
  font-family: 'aa';
}

.safe-area-inset-bottom {
  padding-bottom: constant(safe-area-inset-bottom);
  /* 兼容 IOS>11.2 */
  padding-bottom: env(safe-area-inset-bottom);
}
