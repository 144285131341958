.content {
  width: 100vw;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

.content p{
  width: 100vw;
  text-align: center;
}