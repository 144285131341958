.nav {
  width: 100vw;
  height: calc(60px + constant(safe-area-inset-bottom));
  height: calc(60px + env(safe-area-inset-bottom));
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-size: 12px;
  background-color: #fff;
  box-shadow: 0px 0px 10px #ccc;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

.nav div {
  flex: 1;
}

.nav div>a {
  display: inline-block;
  margin: 0 auto;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s;
}

.nav div>span {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 4px;
}

.nav div>a>img {
  display: inline-block;
  width: 16px;
}
